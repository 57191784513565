.restaurant-pop-up h4 {
  font-size: 16px;
}

.restaurant-pop-up a {
  border-color: #0074d9;
  border-radius: 4px;
  font-size: 12px;
  color: #0074d9;
  padding: 7px 10px;
  display: block;
  margin-top: 15px;
}

.restaurant-pop-up a:hover {
  border-color: #0074d9;
  background-color: #0074d9;
  color: #fff;
}
