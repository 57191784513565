.footer {
  background-color: #000015;
  padding: 30px 100px 30px 100px !important;
}

.footer .top-footer h1 {
  text-transform: uppercase;
  color: white;
  font-size: 40px;
}

.footer .top-footer p {
  font-size: 20px;
}

.footer .top-footer a.rn-btn {
  margin-top: 40px;
  border-color: white !important;
  color: black !important;
  background-color: white !important;
}

.footer .bottom-footer {
  margin-top: 20px;
  border-top: 2px solid white;
  padding-top: 30px;
}

@media only screen and (max-width: 992px) {
  .footer {
    padding: 30px 0 !important;
  }

  .footer .header-btn {
    margin-bottom: 20px !important;
    text-align: left !important;
  }
}

@media only screen and (max-width: 575px) {
  .footer .top-footer a.rn-btn {
    margin-top: 20px;
  }

  .footer .bottom-footer .logo {
    text-align: center !important;
  }
}
