section#city .map-container {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 67px);
}

.map-container .map-sidebar-toggle-button {
  display: block;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  z-index: 9999;
  top: 20px;
  padding: 5px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}

.map-container .map-sidebar-toggle-button i.fa {
  padding-right: 5px;
}

section#city .map-container div[class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}

section#city .map-container div.col-md-12 {
  flex: none;
  width: 100%;
}

section#city .map-container div.col-md-12.filter-row {
  box-shadow: 1px 1px #eee;
  z-index: 999;
}

@media only screen and (max-width: 575px) {
  .map-container.station-selected {
    display: flex;
    flex-direction: column;
  }

  .map-container.station-selected div.col-md-12 {
    order: -1;
    height: 50vh;
    flex: 3;
  }

  .map-container.station-selected .map-sidebar {
    position: relative;
    height: 50vh;
    width: 100% !important;
    max-width: 100% !important;
    flex: 3;
  }

  .map-container.station-selected .legend {
    display: none;
  }

  .map-container .map-sidebar-toggle-button {
    font-size: 0;
  }

  .map-container .map-sidebar-toggle-button span i.fa {
    font-size: 20px;
  }
}
