.transit-authorities h2,
.transit-authorities h3 {
  margin-top: 15px;
}

.transit-authorities label span,
.transit-authorities div.check-all {
  font-size: 14px !important;
}

.transit-authorities .MuiFormControlLabel-root {
  display: block;
}

.transit-authorities .check-all .check-uncheck {
  border: 1px solid #0074d9;
  background: transparent;
  color: #0074d9;
  border-radius: 4px;
  margin-left: -3px;
}

.transit-authorities .check-all .check-uncheck:hover {
  background: #0074d9;
  color: white;
}

.transit-authorities .check-all .check-uncheck:disabled {
  cursor: not-allowed;
  background: #eee;
  border: 1px solid #eee;
  color: black;
}

.transit-info-tooltip {
  padding: 10px 20px !important;
  line-height: 1rem !important;
  width: 200px !important;
  border: 2px solid #ccc;
  background-color: white !important;
  opacity: 0.95 !important;
}

.transit-info-tooltip p {
  color: black;
  font-size: 13px;
}

.transit-info-tooltip a.btn {
  font-size: 13px;
  padding: 5px 7px !important;
}
