.form-group.field.field-array label span i.fa {
  margin-top: 4px;
  margin-right: 5px;
  font-size: 15px;
}

.MuiCheckbox-colorPrimary,
.MuiFormControlLabel-root,
.MuiIconButton-label,
.MuiIconButton-label svg {
  margin-right: 0px !important;
  margin-bottom: 0px !important;
}

.MuiCheckbox-colorPrimary {
  padding: 5px !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #0074d9 !important;
}
