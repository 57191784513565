.logo img {
  height: 35px;
}

.logo .city-name {
  color: white;
  font-size: 20px;
  vertical-align: top;
}

.header-area.header--transparent {
  background-color: #000015;
  z-index: 9999 !important;
}

.header-area .header-wrapper {
  padding: 0 !important;
}

ul.mainmenu > li {
  margin: 0 20px !important;
}

ul.mainmenu > li > a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 800 !important;
}

ul.mainmenu > li > a:hover,
ul.mainmenu > li.has-dropdown > a:hover {
  color: #ccc;
}

.header-right .header-btn {
  margin-left: 10px !important;
}

.header-right .header-btn .rn-btn {
  border: 2px solid white !important;
  font-weight: bolder;
}

span.rn-btn {
  cursor: pointer;
  text-transform: uppercase;
  margin-left: 0px !important;
}

@media only screen and (max-width: 575px) {
  .header-area.header--transparent {
    padding-top: 2px;
    padding-bottom: 5px;
  }

  .header-area.header--transparent .header-left {
    max-width: 75%;
  }

  .header-area .header-wrapper .logo .city-name {
    display: block;
    font-size: 14px;
    margin-left: 5px;
  }

  .header-area .header-wrapper .logo a img {
    width: auto !important;
    margin-top: 5px;
    height: 30px;
  }

  .header-area.header--transparent ul.mainmenu {
    right: -100vw !important;
  }

  .header-area.header--transparent .menu-open ul.mainmenu {
    right: 0 !important;
    width: 150px !important;
  }
}

@media (min-width: 768px) {
  .header-area.header--transparent {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .header-area.header--transparent {
    padding-top: 0;
    padding-bottom: 0;
  }
}
