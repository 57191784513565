@import './scss/style';

body,
a,
p,
div,
button,
li,
span:not(.fa) {
  font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', sans-serif;
}

.spinner-overlay {
  height: calc(100vh - 67px);
  position: absolute;
  top: 67px;
  width: 100%;
  z-index: 9999;
  background-color: white;
  opacity: 0.75;
}

.sk-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: scale(2);
}

section {
  padding-top: 67px;
}

.no-match-404 {
  text-align: center;
}

h1.title,
h2.title {
  font-size: 45px !important;
  text-transform: uppercase;
}

p a {
  text-decoration: underline;
}

.backto-top > div {
  opacity: 0.5;
}

@media only screen and (max-width: 575px) {
  h2.title {
    font-size: 30px !important;
  }
}
