.map-sidebar .station-info {
  margin-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 2px #eee;
}

.map-sidebar .station-info .back-to-ta-link {
  font-size: 14px;
  cursor: pointer;
  color: #0074d9;
}

.map-sidebar .station-info .back-to-ta-link i.fa {
  margin-right: 5px;
}

.map-sidebar .station-info h2.city-title {
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  width: 100%;
  overflow-wrap: break-word;
  margin-bottom: 5px;
}

.map-sidebar .station-info .status-icons {
  width: 100%;
  text-align: center;
}

.map-sidebar .station-info .status-icons i.fa {
  margin: 0 5px;
  font-size: 15px;
  color: white;
  vertical-align: middle;
}

.map-sidebar .station-info .authority-info {
  width: 100%;
  text-align: center;
  font-size: 15px;
  overflow-wrap: break-word;
  padding-top: 5px;
  color: white;
}

.map-sidebar .station-info .calendar-title {
  font-size: 15px;
  font-weight: bolder;
  color: #0074d9;
  text-align: center;
  margin-top: 10px;
}

.map-sidebar .station-info .calendar-title .helper-text {
  display: block;
  font-size: 12px;
  color: black;
}

.map-sidebar .station-info .calendar-title .helper-text .outage-example {
  color: white;
  background: #d55e00;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: normal;
}

.map-sidebar .station-info .elevator-info {
  border: 3px solid;
  padding: 10px;
  margin-top: 20px;
  font-weight: bolder;
}

.map-sidebar .station-info .elevator-info.elevator-out {
  border-color: #e69f00;
  color: #e69f00;
}

.map-sidebar .station-info .elevator-info.accessible {
  border-color: #009e73;
  color: #009e73;
}

.map-sidebar .station-info .elevator-info.not-accessible {
  border-color: #d55e00;
  color: #d55e00;
}

.map-sidebar .station-info .elevator-info div i.fa {
  margin-right: 10px;
}

.map-sidebar .station-info .react-calendar {
  margin-top: 10px;
  border: none;
  opacity: 0.7;
}

.map-sidebar .station-info .react-calendar button {
  padding: 7px;
  border: 1px solid white;
  border-radius: 4px;
  color: black;
  background-color: white;
}

.map-sidebar .station-info .react-calendar button:disabled {
  background-color: #eee;
  color: black !important;
}

.map-sidebar
  .station-info
  .react-calendar
  .react-calendar__viewContainer
  button {
  pointer-events: none;
}

.map-sidebar .station-info .react-calendar .react-calendar__navigation {
  margin-bottom: 0;
  height: 100%;
}

.map-sidebar .station-info .react-calendar .react-calendar__navigation button {
  padding: 7px;
}

.map-sidebar
  .station-info
  .react-calendar
  .react-calendar__navigation
  button
  span {
  font-weight: bold;
}

.map-sidebar
  .station-info
  .react-calendar
  .react-calendar__month-view__weekdays__weekday
  abbr {
  cursor: default;
}

.map-sidebar .station-info .react-calendar .date-with-elevator-outage {
  background-color: #d55e00;
  color: white;
}
