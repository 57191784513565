.station-pop-up h4 {
  font-size: 16px;
}

.station-pop-up h4 span {
  display: block;
  font-size: 12px;
  color: #666;
  padding-top: 7px;
}

.station-pop-up a {
  border-color: #0074d9;
  border-radius: 4px;
  font-size: 12px;
  color: #0074d9;
  padding: 7px 10px;
  display: block;
  margin-top: 15px;
}

.station-pop-up a:hover {
  border-color: #0074d9;
  background-color: #0074d9;
  color: #fff;
}

.station-pop-up .icons i.fa {
  font-size: 20px;
  margin-right: 10px;
}

.station-pop-up .elevator-out {
  border: 3px solid;
  padding: 10px;
  margin-top: 10px;
  font-weight: bolder;
  border-color: #f18f26;
  color: #f18f26;
}

.station-pop-up .elevator-out i.fa {
  margin-right: 10px;
}
