.rn-about-area {
  padding-bottom: 0 !important;
}

.rn-about-area h2.title,
.rn-team-area h2.title {
  font-size: 40px !important;
}

.rn-team-area .service.service__style--2 {
  padding: 60px 30px !important;
}

.rn-team-area .contributor-name {
  margin-right: 5px;
  font-size: 22px;
  font-weight: 300;
}

.rn-team-area .team-container.legend {
  padding: 0;
  margin-bottom: 10px;
}

.rn-team-area .team-container.legend > span {
  margin-right: 10px;
}

.rn-team-area .team-container .team {
  color: black;
  padding: 2px 5px;
  margin-right: 3px;
  font-size: 12px;
}

.rn-team-area .team-container .team.R {
  background-color: #9ab7d3;
}

.rn-team-area .team-container .team.D {
  background-color: #97c1a9;
}

.rn-team-area .inactive-contributors a {
  color: #007bff !important;
  margin-left: 2px;
  margin-right: 2px;
}

.rn-about-area p.description {
  color: black !important;
  font-size: 25px;
  line-height: 1.5em;
}

.rn-team-area {
  padding-top: 60px !important;
}

.rn-team-area .contributor-card {
  margin-bottom: 30px;
  max-height: 200px;
}

.rn-team-area .contributor-card .contributor-card-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  border: 1px solid transparent;
  z-index: -999;
}

.rn-team-area .contributor-card .service.service__style--2:before {
  background: rgba(0, 0, 0, 0.5) !important;
}

.rn-team-area .contributor-card .content {
  bottom: 30px !important;
}

.rn-team-area .contributor-card .content h4 {
  color: white !important;
  text-transform: uppercase;
  font-size: 15px;
  width: 100%;
}

.rn-team-area .contributor-card ul.social-icon {
  position: absolute;
  top: 0;
  left: 20px;
  padding: 0;
  z-index: 2;
  list-style: none;
  display: flex;
  margin: 0 -10px;
}

.rn-team-area .contributor-card ul.social-icon li {
  margin: 0 5px;
  -webkit-transform: translateY(8px) scale(0.8);
  transform: translateY(8px) scale(0.8);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
}

.rn-team-area .contributor-card:hover ul.social-icon li {
  visibility: visible;
  opacity: 1;
}

.rn-team-area .contributor-card:hover ul.social-icon li a {
  color: white !important;
}

@media only screen and (max-width: 575px) {
  .rn-about-area h2.title,
  .rn-team-area h2.title {
    font-size: 30px !important;
  }

  .rn-about-area p.description {
    text-align: justify;
  }

  .rn-about-area.ptb--120 {
    padding-bottom: 20px !important;
  }

  .rn-team-area.ptb--120 {
    padding-top: 40px !important;
  }
}

@media (min-width: 768px) {
  .rn-about-area.ptb--120 {
    padding-bottom: 20px !important;
  }

  .rn-team-area.ptb--120 {
    padding-top: 40px !important;
  }
}

@media only screen and (max-width: 767px) {
  .rn-team-area .service.service__style--2 {
    padding: 60px 20px !important;
    margin-top: 0px !important;
  }

  .rn-team-area .contributor-card {
    max-height: 100px;
  }

  .rn-team-area .contributor-card .contributor-card-overlay {
    background: rgba(0, 0, 0, 0);
  }

  .rn-team-area .contributor-card .content {
    bottom: 90px !important;
  }

  .rn-team-area .contributor-card .content h4 {
    font-size: 12px;
  }

  .rn-team-area .team-container .team {
    font-size: 10px;
  }
}
