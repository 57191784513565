.home-content .footer {
  padding-top: 100px !important;
}

.react-parallax-bgimage {
  width: calc(100% + 1px) !important;
  height: calc(100% + 67px) !important;
  top: -67px;
}

.parallax-overlay {
  height: 100vh;
  width: calc(100% + 1px);
  position: absolute;
  top: 0;
  left: -1px;
  background: rgba(0, 0, 0, 0.5);
}

.slider-activation {
  width: calc(100% + 1px);
}

.react-parallax-content .slide.slide-style-2.slider-paralax {
  padding-top: 0 !important;
  height: 100vh;
  padding-bottom: 0 !important;
}

.react-parallax-content .slide.slide-style-2.slider-paralax .inner {
  padding-top: 0 !important;
}

.slide.slide-style-2 .inner.banner-container {
  padding-left: 100px;
  padding-right: 100px;
}

.slide.slide-style-2 .inner.banner-container h1.title {
  line-height: 50px !important;
  color: white !important;
  -webkit-text-fill-color: white;
  font-weight: 800 !important;
}

.slide.slide-style-2 .inner.banner-container p.description span {
  display: block;
}

.banner-container .slide-btn {
  margin-top: 20px !important;
}

.home-section {
  background: black;
  color: white;
  position: relative;
}

.home-section .home-section-title {
  position: absolute;
  bottom: 50px;
  z-index: 1;
}

.home-section .home-section-title.align-left {
  left: 26%;
  text-align: left;
}

.home-section .home-section-title.align-right {
  right: 26%;
  text-align: left;
}

.home-section .home-section-title h1 {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 75px;
  letter-spacing: 2px;
}

.home-section img.home-section-image.align-left {
  float: right;
}

.home-section img.home-section-image.align-right {
  float: left;
}

.home-section p {
  font-size: 17px;
}

.home-section.solution-section .home-section-title {
  bottom: 110px;
}

.home-section.solution-section p {
  margin-top: 85px;
}

@media only screen and (max-width: 1200px) {
  .home-section p {
    line-height: 1.3em;
  }

  .home-section .home-section-title h1 {
    font-size: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .home-content .footer {
    padding-top: 30px !important;
  }

  .home-section .home-section-title {
    position: relative;
    left: 0 !important;
    bottom: 0 !important;
    margin-bottom: 0px !important;
  }

  .home-section p {
    line-height: 1.5em;
    margin-bottom: 30px !important;
  }

  .slide.slide-style-2 .inner.banner-container {
    padding-left: 10px;
    padding-right: 10px;
    text-align: left !important;
  }
}

@media (min-width: 768px) {
  .slide.slide-style-2 {
    padding-top: 300px !important;
  }
}

@media only screen and (max-width: 575px) {
  .slide.slide-style-2 .inner.banner-container p.description span {
    padding: 5px 0;
  }
}
