img.leaflet-marker-icon {
  border-radius: 50px;
  border: 1px solid #fff;
}

img.leaflet-marker-icon.accessible {
  background: #009e73;
}

img.leaflet-marker-icon.elevator-out {
  background: #e69f00;
}

img.leaflet-marker-icon.not-accessible {
  background: #d55e00;
}

img.leaflet-marker-icon.restaurant {
  background: #0072b2;
}

img.leaflet-marker-icon.restroom {
  background: #cc79a7;
  font-size: 20px;
}
