/*=====================
    Pagination Styles 
=======================*/

.rn-pagination {
  ul {
    &.page-list {
      @extend %liststyle;
      li {
        display: inline-block;
        padding: 5px;
        a {
          padding: 0 10px;
          display: block;
          text-align: center;
          line-height: 38px;
          min-width: 42px;
          height: 42px;
          border: 2px solid #0000004d;
          background: #fff;
          color: #000000;
          position: relative;
          z-index: 2;
          font-weight: 600;
          border-radius: 4px;

          &::before {
            @extend %theme-gradient;
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: '';
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            @extend %transition;
          }
          &:hover {
            color: #ffffff;
            border-color: $theme-color;
            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }
        &.active {
          a {
            color: #ffffff;
            border-color: $theme-color;
            &::before {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.line-separator {
  border-bottom: 1px solid #ebebeb;
}
