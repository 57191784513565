.modal-dialog {
  z-index: 99999;
  top: 50px;
  right: 50px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.modal-dialog .modal-content .modal-body fieldset {
  padding-left: 0 !important;
}

.modal-dialog .modal-content .modal-body fieldset .form-group {
  margin-bottom: 0 !important;
}

.modal-dialog .modal-content .modal-body fieldset textarea {
  padding: 5px;
  height: 8em;
}

.modal-dialog .modal-content .modal-body button {
  float: right;
}

.modal-dialog .modal-header {
  padding-bottom: 0px;
}

.modal-container {
  position: relative;
}
.modal-container .modal,
.modal-container .modal-backdrop {
  position: absolute;
}
