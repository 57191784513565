.creative-service .city-card {
  margin-bottom: 30px;
}

.service.service__style--2 {
  width: 100%;
  height: 100%;
  border-radius: 4px !important;
}

.service.service__style--2:before {
  background: rgba(0, 0, 0, 0) !important;
}

.service.service__style--2 h2.title {
  font-size: 40px !important;
}

.service.service__style--2 .city-card-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  border: 1px solid transparent;
  z-index: -999;
}

.service.service__style--2 .content {
  position: relative;
  padding-top: 100px;
  bottom: -40px;
}

.service.service__style--2 .content h3 {
  text-transform: uppercase;
  font-size: 30px !important;
  margin-top: 30px !important;
  margin-bottom: 0 !important;
}

.service.service__style--2 .content h3,
.service.service__style--2 .content p {
  color: white !important;
}

@media only screen and (max-width: 575px) {
  .service-area h2.title {
    font-size: 30px !important;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
